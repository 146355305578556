<template>
    <div id="page-user-list">

        <vx-card class="mb-4">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <h4>Detail Ticket #{{ ticketData.ticket_number }}</h4>
                    <div class="divider-line my-6"></div>
                </div>
            </div>  
            <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full">
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="opacity-75">Date : </span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full pl-0">                        
                            <h6>{{ ticketData.tanggal }}</h6>
                        </div>
                    </div>
                    <div class="divider my-2 mx-6"></div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="opacity-75">Company Name : </span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full pl-0">                        
                            <h6>{{ ticketData.company.company_name }}</h6>
                        </div>
                    </div>
                    <div class="divider my-2 mx-6"></div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="opacity-75">Subject : </span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full pl-0">                        
                            <h6>{{ ticketData.subject }}</h6>
                        </div>
                    </div>
                    <div class="divider my-2 mx-6"></div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="opacity-75">Description : </span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full pl-0">                        
                            <h6 style="line-height: 1.5" v-html="ticketData.short_desc"></h6>
                        </div>
                    </div>
                </div>
                <div class="vx-col md:w-1/2 w-full">
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="opacity-75">Originator : </span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full pl-0">                        
                            <h6>{{ ticketData.nama }}</h6>
                        </div>
                    </div>
                    <div class="divider my-2 mx-6"></div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="opacity-75">Email : </span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full pl-0">                        
                            <h6>{{ ticketData.email }}</h6>
                        </div>
                    </div>
                    <div class="divider my-2 mx-6"></div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="opacity-75">Priority : </span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full pl-0">                        
                            <h6>{{ ticketData.priority }}</h6>
                        </div>
                    </div>
                    <div class="divider my-2 mx-6"></div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="opacity-75">Deadline : </span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full pl-0">                        
                            <h6>{{ ticketData.deadline }}</h6>
                        </div>
                    </div>
                    <div class="divider my-2 mx-6"></div>
                    <div class="vx-row">
                        <div class="vx-col sm:w-1/3 w-full flex justify-end">
                            <span class="opacity-75">Status : </span>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full pl-0">                        
                            <h6>
                                <vs-chip transparent :color="(ticketData.status == 'Closed') ? 'dark' : 'primary'">{{ ticketData.status }}</vs-chip>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
        
        <vx-card class="mb-base">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <h4>Activities</h4>
                    <div class="divider-line my-6"></div>
                </div>
            </div>  

            <div v-if="ticketData.detail.length > 0" style="max-height: 500px; overflow-y: scroll">
                <template v-for="data in ticketData.detail">
                    <div class="flex mb-6" :key="data.waktu">
                        <vs-avatar class="mr-4"/>
                        
                        <div class="vx-col">
                            <div class="flex mx-4 mb-2" style="font-size: 12px;">
                                <div class="vx-row">
                                    <b class="text-primary">{{ data.pic }}</b>
                                </div>
                                <div class="vx-row mx-8">
                                    <feather-icon icon="CalendarIcon" svgClasses="h-5 w-5 mr-2"/>
                                    <span>{{ data.last_modified }}</span>
                                </div>
                                <div class="vx-row mr-8">
                                    <feather-icon icon="ClockIcon" svgClasses="h-5 w-5 mr-2"/>
                                    <span>{{ data.waktu }} WIB</span>
                                </div>
                                <div class="vx-row">
                                    <feather-icon icon="InfoIcon" svgClasses="h-5 w-5 mr-2"/>
                                    <span>{{ data.status }}</span>
                                </div>
                            </div>
                            <div class="item w-full p-4" style="background: RGBA(0, 0, 0, .04); border-radius: 10px" v-html="data.description"></div>
                            
                        </div>
                    </div>
                </template>
            </div>

            <div class="flex" v-else>
                <div class="item w-full p-4" style="background: RGBA(0, 0, 0, .04); border-radius: 10px">
                    Belum ada aktivitas apapun 
                </div>
            </div>
        </vx-card>

        <vx-card class="mb-base">
            <div class="vx-row">
                <div class="vx-col w-full">
                    <h4>Add Detail</h4>
                    <div class="divider-line my-6"></div>
                </div>
            </div>  

            <div class="vx-row mb-6">
                <div class="vx-col md:w-1/2">
                    <span>{{$i18n.t('Attachment')}}</span>
                    <vs-input type="file" name="file" @change="handleFileChange($event.target.name, $event.target.files)" class="w-full p-0"/>
                </div>
                <div class="vx-col md:w-1/2">
                    <span>Status</span>
                    <v-select :options="statusOptions" :clearable="false" v-model="status" v-validate="'required'" name="type" placeholder="Choose Status"></v-select>
                </div>
            </div>

            <quill-editor v-model="description"></quill-editor>

            <div class="flex mt-4">
                <vs-button class="mr-3 mb-2" @click="saveData">Submit</vs-button>
                <vs-button color="warning" type="border" class="mb-2" @click="resetForm">Reset</vs-button>
            </div>
        </vx-card>
    </div>
</template>

<script>

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
    components: {
        quillEditor
    },
    data() {
        return {
            attachment: {},
            description: null,
            loaded: false,
            ticketData: {},
            status: { 
                label: "", 
                value: "" 
            },
            statusOptions: [
                {
                    label: "Opened",
                    value: "Opened"
                },
                {
                    label: "In Process",
                    value: "In Process"
                },
                {
                    label: "In Process Principal",
                    value: "In Process Principal"
                },
                {
                    label: "In Process Customer",
                    value: "In Process Customer"
                },
                {
                    label: "Closed",
                    value: "Closed"
                }
            ],
        }
    },
    methods : {
        handleFileChange (event, files) {
            this.attachment = files[0];
        },
        getTicketData () {
            const self = this
            this.$store.dispatch('ticket/detailItem', { 
                id: self.$secure.decrypt(this.$route.params.id)
            })
            .then(res => {
                const data = res.data.data
                self.ticketData = data
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
            .finally(() => {
                this.onLoaded()
            })
        },
        saveData () {
            const self = this

            const formData = new FormData()
            formData.set('status', self.status.value)
            formData.set('description', self.description)
            formData.append('upload', self.attachment)
            formData.append('_method', 'PUT')

            this.$store.dispatch('ticket/updateItem', {
                id: self.$secure.decrypt(this.$route.params.id),
                body: formData
            })
            .then(res => {
                self.$vs.notify({
                    color: 'success',
                    title: 'Success',
                    text: 'Detail Trouble Ticket has been saved'
                })
                self.resetForm()
                self.loading()
                self.getTicketData()
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
        },
        resetForm() {
            this.attachment = null
            this.status = {
                label: "",
                value: ""
            }
            this.description = null
        },
        onLoaded(){
            this.loaded = true;
            this.$vs.loading.close()
        },
        loading () {
            this.loaded = false;

            this.$vs.loading({
                type: 'border',
                color: 'primary',
                text: `Wait a minute, It's getting data.`
            })
        },
    },
    created() {
        this.loading()
        this.getTicketData()
    }
}
</script>

<style>
    .ql-editor {
        min-height: 300px;
    }

    .divider-line {
        border-bottom: 2px solid #EDEDED;
        border-width: thin;
    }

    .divider {
        border-bottom: 2px dashed #DDDDDD;
        border-width: thin;
    }

    .vs-tabs--li {
        border: 1px solid #EDEDED;
        padding: 0 10px;
    }

    .activeChild {
        border: 1px solid rgba(var(--vs-primary), 0.4) !important;;
    }

    .vs__selected-options {
        padding: 4px
    }
</style>
